export default {
  id: 'reworker',
  debug: false,
  domain: 'rw',
  title: 'Reworker',
  app: 'reworker',
  localServer: 'lk.reworker.ru',
  defaultServer: 'https://rw.orderadmin.ru',
  webService: 'wss://centrifugo.orderadmin.eu/connection/websocket',
  securityLogo: 'assets/rwLogo.png',
  logoSrc: 'assets/rwLogoWhite.png',
  logoLeft: 'assets/logoTop.png',
  logoTop: 'assets/rwLogo-nosign.png',
  support: true,
  theme: 'default',
  domains: [
    {
      name: 'rw',
      value: 'rw'
    },
    {
      name: 'kz',
      value: 'kz'
    }
  ],
  locale: {
    name: 'Русский',
    locale: 'ru',
    flag: 'ru'
  },
  googleApiKey: 'AIzaSyAjsWnMXpwZKAMMVENXoGNuTLDHsvBACVg',
  settings: {
    rw: {
      accounts: {
        contractor: 11,
        currency: 1,
        rates: {
          deposit: 2,
          payment: 6
        }
      },
      'delivery-request': {
        'delivery-services-request': true
      }
    },
    kz: {
      accounts: {
        contractor: 331,
        currency: 4,
        rates: {
          deposit: 30,
          payment: 6
        }
      },
      'delivery-request': {
        'delivery-services-request': true
      }
    }
  },
  storage: {
    parents: [
      {
        name: 'Новосибирск',
        id: 861
      },
      {
        name: 'Новосибирск',
        id: 861
      }
    ]
  },
  labellingSettings: {
    notification: {
      shippingManifest: 'Are you sure that you want to continue without consumption?',
      labelConfirm: false
    },
  },
  defaultDimensions: {
    weight: 1000,
    dimensions: {
      x: 100,
      y: 100,
      z: 100
    }
  },
  deliveryServices: {
    filter: []
  },
  locality: {
    filter: []
  },
  servers: [
    {
      name: 'reWorker Russia',
      domain: 'rw',
      host: 'https://rw.orderadmin.ru'
    },
    {
      name: 'reWorker Kazakhstan',
      domain: 'kz',
      host: 'https://rw.orderadmin.ru'
    },
    {
      name: 'reWorker Bulgaria',
      domain: 'rwbg',
      host: 'https://panel.orderadmin.eu'
    },
    {
      name: 'reWorker Alpha',
      domain: 'rw',
      host: 'https://alpha.orderadmin.eu'
    },
    {
      name: 'reWorker Beta',
      domain: 'rw',
      host: 'https://beta.orderadmin.ru'
    }
  ],
  wizardBannedRoles: [
    3,
    29
  ],
  isRequiredDeliveryRequest: false,
  acceptance: {
    defaultState: 'preset'
  },
  automaticCreatedEntities: {
    rw: {
      legalEntity: {
        state: 'active',
        type: 'customer'
      },
      depositAccount: {
        type: 'deposit',
        contractor: 11,
        creditLimit: 0,
        rate: 2,
        currency: 1
      },
      paymentAccount: {
        type: 'payment',
        contractor: 11,
        creditLimit: 0,
        rate: 6,
        currency: 1
      }
    },
    kz: {
      legalEntity: {
        state: 'active',
        type: 'customer'
      },
      depositAccount: {
        type: 'deposit',
        contractor: 331,
        creditLimit: 0,
        rate: 6,
        currency: 4
      },
      paymentAccount: {
        type: 'payment',
        contractor: 331,
        creditLimit: 0,
        rate: 6,
        currency: 4
      }
    }
  },
  wizardTabs: {
    warehouse: {
      state: 'active',
      type: 'virtual'
    }
  },
  orders: {
    enableRecipient: true
  }
}
