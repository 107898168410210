export default {
  id: 'shippedbg',
  debug: false,
  app: 'orderadmin',
  title: 'ShippedBG',
  domain: 'shippedbg',
  localServer: 'shippedbgapp.orderadmin.eu',
  defaultServer: 'https://shippedbg.orderadmin.eu',
  securityLogo: 'assets/shippedbg-with-text.png',
  logoSrc: 'assets/shippedbg-logo.png',
  logoLeft: 'assets/shippedbg-logo.png',
  logoTop: 'assets/shippedbg-logo.png',
  theme: 'default',
  servers: [],
  locale: {
    name: 'English',
    locale: 'en',
    flag: 'us'
  },
  automaticCreatedEntities: {
    shippedbg: {
      sender: {},
      shop: {}
    }
  }
}
