export default {
  id: 'cargoplanet',
  debug: false,
  app: 'orderadmin',
  title: 'CargoPlanet',
  domain: 'cargoplanetbg',
  localServer: 'cargoplanetapp.orderadmin.eu',
  defaultServer: 'https://pro.oawms.com',
  securityLogo: 'assets/cargoplanet.png',
  logoSrc: 'assets/cargoplanet.png',
  logoLeft: 'assets/cargoplanet-top.png',
  logoTop: 'assets/cargoplanet-top.png',
  theme: 'default',
  servers: [],
  locale: {
    name: 'English',
    locale: 'en',
    flag: 'us'
  },
  automaticCreatedEntities: {
    cargoplanetbg: {
      sender: {},
      shop: {}
    }
  },
  orders: {
    enableRecipient: true
  }
}
