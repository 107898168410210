export default {
  id: 'fastprepusa',
  debug: false,
  app: 'orderadmin',
  title: 'FastPrepUSA',
  domain: 'fastprepusa',
  localServer: 'app.fastprepusa.com',
  defaultServer: 'https://pro.oawms.com',
  securityLogo: 'assets/fastPrepAvatar.png',
  logoSrc: 'assets/fastPrepAvatar.png',
  logoLeft: 'assets/fastPrepLong.png',
  logoTop: 'assets/fastPrepLong.png',
  theme: 'default',
  formats: {
    internationalDate: 'LLL',
    date: 'MM/DD/YYYY',
    fullDate: 'MM/DD/YYYY hh:mm A z',
    defaultTimezone: 'America/New_York'
  },
  servers: [],
  locale: {
    name: 'English',
    locale: 'en',
    flag: 'us'
  },
  automaticCreatedEntities: {
    fastprepusa: {
      sender: {},
      shop: {}
    }
  },
  defaults: {
    addressFormat: 'domestic',
    order: {
      paymentState: 'paid'
    }
  },
  defaultDimensions: {
    weight: 1,
    dimensions: {
      x: 10,
      y: 10,
      z: 10
    },
    weightUnit: 'lb',
    dimensionUnit: 'in'
  }
}
